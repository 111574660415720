import { BookOpen, QrCode, User, Image, ShieldCheck, Clock, Globe } from 'lucide-react'
import i18next from './i18n'

export const featureList = [
	{
		title: i18next.t('homepage.features.feature_list.item_1.title'),
		description: i18next.t('homepage.features.feature_list.item_1.description'),
		icon: <BookOpen className="h-8 w-8 text-blue-500"/>,
	},
	{
		title: i18next.t('homepage.features.feature_list.item_2.title'),
		description: i18next.t('homepage.features.feature_list.item_2.description'),
		icon: <QrCode className="h-8 w-8 text-blue-500"/>,
	},
	{
		title: i18next.t('homepage.features.feature_list.item_3.title'),
		description: i18next.t('homepage.features.feature_list.item_3.description'),
		icon: <User className="h-8 w-8 text-blue-500"/>,
	},
	{
		title: i18next.t('homepage.features.feature_list.item_4.title'),
		description: i18next.t('homepage.features.feature_list.item_4.description'),
		icon: <Image className="h-8 w-8 text-blue-500"/>,
	},
	{
		title: i18next.t('homepage.features.feature_list.item_5.title'),
		description: i18next.t('homepage.features.feature_list.item_5.description'),
		icon: <ShieldCheck className="h-8 w-8 text-blue-500"/>,
	},
	{
		title: i18next.t('homepage.features.feature_list.item_6.title'),
		description: i18next.t('homepage.features.feature_list.item_6.description'),
		icon: <Clock className="h-8 w-8 text-blue-500"/>,
	},
	{
		title: i18next.t('homepage.features.feature_list.item_7.title'),
		description: i18next.t('homepage.features.feature_list.item_7.description'),
		icon: <Globe className="h-8 w-8 text-blue-500"/>,
	},
]
