import MaxWidthWrapper from '@/Components/MaxWidthWrapper'
import Pricing from '@/Components/Welcome/Pricing.jsx'
import { featureList } from '@/lib/featureList.jsx'
import RootLayout from '@/Layouts/RootLayout.jsx'
import { useTranslation } from 'react-i18next'
import FAQAccordion from '@/Components/Welcome/FAQAccordion.jsx'
import { AuroraBackground } from '@/Components/ui/aurora-background.jsx'
import GettingStarted from '@/Components/Buttons/GettingStarted.jsx'

const Welcome = ({ auth }) => {
    const { t } = useTranslation()

    return (
        <RootLayout auth={auth}>
            <AuroraBackground className="grainy">
                <MaxWidthWrapper className="relative lg:top-20 flex flex-col items-center justify-center text-center">
                    <h1 className="max-w-5xl text-5xl font-bold md:text-6xl lg:text-7xl">
                        {t('homepage.heading_1')}
                        <br/>
                        <span className="text-blue-600">
                        {t('homepage.heading_2')}
                    </span>
                        <br/>
                        {t('homepage.heading_3')}
                    </h1>
                    <p className="mt-5 max-w-prose text-zinc-700 sm:text-lg">
                        {t('homepage.description')}
                    </p>
                    <GettingStarted size="lg" className="mt-2 mb-16" />
                </MaxWidthWrapper>

                {/* value proposition section */}
                <div className="grainy">
                    <div className="relative isolate">
                        <div className="mx-auto max-w-6xl px-6 lg:px-8">
                            <div className="mt-10 flow-root sm:mt-20">
                                <div
                                    className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                                    <img
                                        alt="product preview"
                                        src="assets/preview/henry_ford.png"
                                        className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AuroraBackground>

            {/* Feature section */}
            <section className="mx-auto pt-1 mb-32 mt-32 max-w-7xl sm:mt-56"
                     id="features">
                <MaxWidthWrapper className="mb-8 mt-24 max-w-7xl">
                    <div className="mt-6 px-4 pt-6 xl:mt-1">
                        <div className="mx-auto max-w-2xl sm:text-center">
                            <h2 className="mt-2 font-bold text-4xl text-gray-900 sm:text-5xl">
                                {t('homepage.features.title')}
                            </h2>
                            <p className="mt-4 text-lg text-gray-600">
                                {t('homepage.features.description')}
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center px-4">
                        <div
                            className="max-w-screen-xl lg:flex lg:flex-wrap lg:justify-around xl:mt-6 xl:px-8">
                            {featureList.map((item, index) => (
                                <div
                                    className="mt-10 flex w-full max-w-md lg:w-1/3 lg:flex-col lg:p-5"
                                    key={index}
                                >
                                    <div className="mr-4 lg:mr-0">
                                        {item.icon}
                                    </div>
                                    <div className="lg:mt-7">
                                        <h4 className="text-sm font-semibold text-gray-800 lg:text-lg xl:text-xl">
                                            {item.title}
                                        </h4>
                                        <p className="mt-2 text-xs leading-normal text-gray-600 lg:text-sm xl:text-base">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </MaxWidthWrapper>
            </section>

            <section className="mx-auto pt-1 lg:pt-8 max-w-5xl sm:mt-56"
                     id="pricing">
                <Pricing auth={auth}/>
            </section>

            {/* FAQ section */}
            <section className="pt-1 h-screen mx-auto mb-52 max-w-5xl sm:mt-56"
                     id="faq">
                <div className="mb-8 mt-24 max-w-7xl">
                    <div className="mt-6 px-4 pt-6 xl:mt-1">
                        <div className="mx-auto max-w-2xl sm:text-center">
                            <h2 className="mt-2 font-bold text-4xl text-gray-900 sm:text-5xl">
                                FAQ
                            </h2>
                            <p className="mt-4 text-lg text-gray-600">
                                Hier werden häufig gestellte Fragen beantwortet.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center px-4 mt-2">
                        <div
                            className="lg:flex lg:flex-wrap lg:justify-around mt-6">
                            <FAQAccordion/>
                        </div>
                    </div>
                </div>
            </section>
        </RootLayout>
    )
}

export default Welcome
