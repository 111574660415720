import React, { useState } from 'react'
import { Minus, Plus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { Button } from '@/Components/ui/button.jsx'

const FAQAccordion = () => {
  const { t } = useTranslation()
  const [openIndex, setOpenIndex] = useState(null)

  const handleAccordionClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <div className="grid place-items-center">
      <div className="mx-auto rounded border">
        <ul className="bg-white p-6 shadow-sm">
          {/* FAQAccordion Items */}
          {accordionData.map((item, index) => (
            <li
              key={index}
              className={`transition hover:bg-blue-50 px-4 ${
                openIndex === index ? 'bg-blue-50' : ''
              }`}
            >
              {/* FAQAccordion Header */}
              <div
                className="accordion-header cursor-pointer transition flex items-center h-16"
                onClick={() => handleAccordionClick(index)}
              >
                {openIndex === index ? (
                  <Minus size={24} className="mr-2"/>
                ) : (
                  <Plus size={24} className="mr-2"/>
                )}
                <h3 className="flex-1 select-none">{item.title}</h3>
              </div>
              {/* FAQAccordion Content */}
              <div
                className={`accordion-content px-5 pt-0 overflow-hidden max-h-0 ${
                  openIndex === index ? 'max-h-full' : ''
                }`}
              >
                {index === (accordionData.length - 1) ? (
                  <>
                    <p className="leading-6 font-light">
                      {item.content}
                    </p>
                    <a href="mailto:helpdesk@aiphton.de">
                      <Button className="px-6 py-2 my-5">
                        {t('buttons.sendEmail')}
                      </Button>
                    </a>
                  </>
                ) : (
                  <p className="leading-6 font-light pb-9 ">
                    {item.content}
                  </p>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

// Data for accordion items
const accordionData = [
  {
    title: t('homepage.faq.reasonOfUse.title'),
    content: t('homepage.faq.reasonOfUse.description'),
  },
  {
    title: t('homepage.faq.reasonToTrust.title'),
    content: t('homepage.faq.reasonToTrust.description'),
  },
  {
    title: t('homepage.faq.reasonToChoose.title'),
    content: t('homepage.faq.reasonToChoose.description'),
  },

  {
    title: t('homepage.faq.questionsRemain.title'),
    content: t('homepage.faq.questionsRemain.description'),
  },
]

export default FAQAccordion
