import { ArrowRight } from 'lucide-react'
import { Button } from '@/Components/ui/button.jsx'
import { useTranslation } from 'react-i18next'

const UpgradeButton = ({ disabled }) => {
	const { t } = useTranslation()

	return (
		<Button className='w-full' disabled={disabled}>
			{t('buttons.upgradeNow')}<ArrowRight className='h-5 w-5 ml-1.5' />
		</Button>
	)
}

export default UpgradeButton
